import React from "react";
import { graphql } from "gatsby";
import { SEngOpt } from "../components/seo";
import Layout from "../components/Layout";
import PWALauncher from "../images/launch.png";
import RenderMarkdown from "../components/RenderMarkdown";
import Product from "../images/svg/cubeV3color.svg";
export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const convertToDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  return (
    <Layout title={"App"}>
      <main>
        <div className="flex flex-col-reverse   align-center justify-center m-auto md:max-w-4xl md:flex-row p-1">
          <div className="w-full md:w-3/4">
            <div class="container pt-10 mx-auto p-5">
              <div className="grow text-4xl bg-slate-50 text-secondary  text-center p-10 rounded-md shadow-lg">
                {frontmatter.title}
              </div>
              <div />
              <div className="text-2xl text-center pt-5 m-10 text-secondary bg-slate-50 rounded-md shadow-lg">
                <article className="prose lg:prose-xl p-5">
                  <RenderMarkdown raw_content={html} />
                </article>
                ...
                <p className="text-sm">
                  updated : {convertToDate(frontmatter.updated)}
                </p>
                <div className="text-2xl pt-5">Subscribe to read more ..</div>
                <a href="https://apps.apple.com/gb/app/nst-wellbeing-coach-app/id6464076667">
                  <div className="flex align-center justify-center m-auto p-5 md:w-5/6 w-2/3">
                    <img src={PWALauncher} alt="Launch PWA" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full self-center">
            <Product className="m-auto md:w-10/12 w-3/4 drop-shadow-md" />
          </div>
        </div>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        desc
        title
        created
        updated
      }
    }
  }
`;

export function Head({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  return (
    <SEngOpt title={frontmatter.title}>
      <meta name="description" content={frontmatter.desc} />
    </SEngOpt>
  );
}
